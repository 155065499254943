import { useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { TypologyMultiselect } from 'src/components/TypologyMultiselect';

import type {
  CATEGORIES,
  CONTRACT,
  TYPOLOGIES,
} from 'src/constants/typologies';

import { useTypologyOptions } from './useTypologiesOptions';

import { getDefaultValue } from './utils';

import { FooterButton } from '../FooterButton';

import css from './styles.module.scss';

interface TypologySelectProps {
  categoryId?: CATEGORIES;
  contractId?: CONTRACT;
  typeId?: TYPOLOGIES[];
  inModal?: boolean;
  onChange: (categoryId?: CATEGORIES, typeId?: TYPOLOGIES[]) => void;
}

export function TypologySelect({
  contractId,
  categoryId,
  typeId,
  inModal = false,
  onChange,
}: TypologySelectProps) {
  const { trans } = useTranslations();
  const multiselectRef = useRef<HTMLDivElement>(null);
  const [, setTypologySelection] = useState<string[]>([]);
  const options = useTypologyOptions(contractId);

  const handleSelectTypology = (options?: string[]) => {
    if (!options) {
      return;
    }

    const { categoryId, typeId } = options.reduce(
      (result, opt) => {
        const [categoryId, typologyId] = opt.split('_') as [
          CATEGORIES,
          Optional<TYPOLOGIES>,
        ];

        const typeId = [...(result.typeId || [])];

        if (typologyId) {
          typeId.push(typologyId);
        }

        return {
          categoryId,
          typeId,
        };
      },
      {} as {
        categoryId?: CATEGORIES;
        typeId?: TYPOLOGIES[];
      }
    );

    onChange(categoryId, typeId);
    setTypologySelection(options);
  };

  const handleFooterClick = () => {
    const control = multiselectRef?.current?.querySelector<HTMLElement>(
      '.js-multiselect-control'
    );

    if (!control) return;

    control.click();
  };

  return (
    <>
      {inModal && (
        <span className={css['ag-typologySelect__label']}>
          {trans('lbl_typology', { capitalize: true })}
        </span>
      )}
      <TypologyMultiselect
        ref={multiselectRef}
        customClass={clsx(
          css['ag-typologySelect'],
          inModal && css['ag-typologySelect--inModal'],
          getDefaultValue(categoryId, typeId) && 'is-selected'
        )}
        options={options}
        value={getDefaultValue(categoryId, typeId)}
        onChange={(options) => handleSelectTypology(options)}
        footer={inModal ? null : <FooterButton onClick={handleFooterClick} />}
      />
    </>
  );
}
